* {
    margin: 0;
    padding: 0;
    /* font-family: "Urbanist", sans-serif; */
    font-family: "Heebo", sans-serif;
}

li {
    font-size: 14px;
    list-style: none;
    display: flex;
    align-items: center;
    color: rgba(181, 181, 181);
    margin: 5px 0;
}
@media (max-width: 767px) {
    li {
        font-size: 13px;
    }
}

li span {
    margin-right: 10px;
    display: flex;
    font-size: 10px;
}

li a {
    color: #c0bbbb;
    text-decoration: none;
    text-transform: capitalize;
}

a {
    text-decoration: none;
    color: black;
}

.footerLink {
    color: #b5b5b5;
    font-size: 20px;
    margin-bottom: 25px;
    text-transform: capitalize;
    font-weight: 500;
}
@media (max-width: 767px) {
    .footerLink {
        margin-bottom: 16px;
    }
}

.copyright {
    color: white;
    height: 50px;
}
@media (max-width: 1050px) {
    .copyright {
        height: 60px;
    }
}

.svgIcon svg {
    width: 18px;
}
.copyrightText {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
}

@media (max-width: 1050px) {
    .copyrightText {
        display: block;
        margin-bottom: 8px;
        font-size: 12px;
    }
}

.paymentLink {
    display: flex;
}

.paymentLink li {
    margin-right: 10px;
    margin-left: 10px;
}
.productListCtn {
    width: 1050px;
    margin: auto;
}
.lop {
    margin: 4px 0;
    background: #f4f4f4;
    padding: 5px;
    cursor: pointer;
}
.prodg {
    display: flex;
    justify-content: space-between;
}
.prodg button {
    padding: 0 22px;
    background: #000;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
}
.welld {
    display: flex;
    justify-content: space-between;
    background: #ececec;
    padding: 5px 0px;
}
.welld h2 {
    margin: 0 5px;
    font-size: 16px;
    font-weight: 500;
    background: #d9d9d9;
    padding: 2px 15px;
}
.filterhd {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;
}
.filterlabel {
    display: flex;
    cursor: pointer;
    font-size: 0.875rem;
    line-height: 1.25rem;
    align-items: center;
}
.filterlabel input {
    width: 1.25rem;
    height: 1.25rem;
    border-radius: 0.25rem;
    border-width: 1px;
    border-color: #d1d5db;
}
.privacyPolicyList li {
    color: #000;
}

.content {
    overflow: hidden;
    max-height: 0;
    /* transition: all 0.5s cubic-bezier(3,1,0,1); */
}
.contentshow {
    height: auto;
    margin-top: 12px;
    max-height: 9999px;
    font-size: 13px;
    color: #383434;
    /* transition: all 0.5s cubic-bezier(1,0,1,0); */
}

/* Menubar */

.dropdown {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 180px;
    box-shadow: 0 0px 6px 0 rgb(0 0 0 / 10%);
    z-index: 1;
}

.dropdown:hover .dropdown-content {
    display: block;
}
.dropdown:hover .down {
    transform: rotate(180deg);
}

.inner {
    position: relative;
    display: inline-block;
    cursor: pointer;
}
.inner-content {
    display: none;
    position: absolute;
    background-color: white;
    min-width: 180px;
    box-shadow: 0 0px 6px 0 rgb(0 0 0 / 10%);
    z-index: 1;
    left: 150px;
    top: -8px;
}
.inner:hover .inner-content {
    display: block;
}
